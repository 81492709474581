import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/Layout"
import { Section, TeamMembers, HeaderRender } from "../components/elements"

const TeamPage = ({ data }) => {
  const node = data.allWordpressPage.edges[0].node

  return (
    <Layout
      title={node.acf.seo_title}
      description={node.acf.seo_description}
      image={node.featured_media ? node.featured_media.source_url : null}
      mobileBackground={node.acf.use_mobile_color_background}
      bodyRender={() => <HeaderRender node={node.title ?? "Meet Our Team"} />}
    >
      {() => (
        <>
          <Section modifiers={"danger"}>
            <div dangerouslySetInnerHTML={{ __html: node.content }}></div>
          </Section>
          <TeamMembers members={data.allWordpressWpTeamMember.edges} />
        </>
      )}
    </Layout>
  )
}

export default TeamPage

export const teamQuery = graphql`
  query TeamPageQuery {
    allWordpressPage(filter: { slug: { eq: "team" } }) {
      edges {
        node {
          id
          title
          content
          path
          featured_media {
            source_url
          }
          acf {
            seo_title
            seo_description
            use_mobile_color_background
          }
        }
      }
    }
    allWordpressWpTeamMember(
      sort: { fields: acf___order, order: ASC }
      limit: 100
    ) {
      edges {
        node {
          id
          title
          content
          path
          featured_media {
            source_url
          }
          acf {
            job_title
            is_owner
          }
        }
      }
    }
  }
`
